<!-- @format -->

<template>
  <b-card title="品牌编辑">
    <validation-observer ref="brandEdit">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="品牌名称" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <validation-provider #default="{ errors }" name="品牌名称" rules="required">
                <b-form-input v-model="editInfo.title" :state="errors.length > 0 ? false : null" placeholder="输入品牌名" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="品牌网站" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <ValidationProvider #default="{ errors }" name="品牌网站" rules="required|url">
                <b-form-input v-model="editInfo.url" :state="errors.length > 0 ? false : null" placeholder="输入品牌网站" />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="品牌logo" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <b-media class="mb-2">
                <template #aside>
                  <b-avatar
                    ref="previewEl"
                    :src="editInfo.logo"
                    :text="avatarText(editInfo.fullName)"
                    size="90px"
                    rounded
                  />
                </template>
                <h4 class="mb-1">
                  {{ editInfo.fullName }}
                </h4>
                <div class="d-flex flex-wrap">
                  <b-button
                    variant="primary"
                    @click="$refs.refInputEl.click()"
                  >
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="inputImageRenderer"
                    >
                    <span class="d-none d-sm-inline">Update</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </div>
              </b-media>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="品牌状态" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <b-form-radio-group
                v-model="editInfo.status"
                :options="[
                  {
                    text: '启用',
                    value: 1,
                  },
                  {
                    text: '禁用',
                    value: 0,
                  },
                ]"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm" :disabled="isRequest"> 提交 </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { computed, onUnmounted, ref, getCurrentInstance } from '@vue/composition-api'
import { required, url } from '@core/utils/validations/validations'

import store from '@/store'

import BrandManagerModule from '@/views/GeneralManagement/ProductManager/Brand/BrandManagerModule'
import { BButton, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BRow, BCard, BMedia, BAvatar, } from 'bootstrap-vue'

import { useRouter, throttle } from '@core/utils/utils'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import axiosIns from '@/libs/axios'

export default {
  name: 'BrandEdit',
  components: {
    BFormRadioGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BMedia,
    BAvatar,
  },

  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'brand-module'
    const { proxy } = getCurrentInstance()

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, BrandManagerModule)
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })

    const editInfo = computed(() => store.state[INVOICE_APP_STORE_MODULE_NAME].brandDetail)
    const { route, router } = useRouter()
    const isRequest = ref(false)

    const brandEdit = ref(null)

    const validationForm = throttle(() => {
      brandEdit.value.validate().then(success => {
        if (success) {
          isRequest.value = true

          delete editInfo.value.created_at
          delete editInfo.value.sort
          delete editInfo.value.com_code

          // 替换图片地址 正则
          editInfo.value.logo = editInfo.value.logo.replace(process.env.VUE_APP_COM_URL, '')
          editInfo.value.logo = editInfo.value.logo.replace(process.env.VUE_APP_COM_URLNS, '')

          store
            .dispatch('brand-module/editInvoices', { ...editInfo.value })
            .then(response => {
              if (response.code == 0) {
                proxy.$bvToast.toast(response.msg || '修改成功', {
                  title: '提示',
                  variant: 'success',
                  solid: true,
                  autoHideDelay: 2000,
                })
                const timer = setTimeout(() => {
                  isRequest.value = false
                  clearTimeout(timer)
                  router.back()
                }, 500)
              } else {
                proxy.$bvToast.toast(response.msg || '修改失败', {
                  title: '提示',
                  variant: 'danger',
                  solid: true,
                  autoHideDelay: 2000,
                })
              }
            })
            .catch(() => {
              isRequest.value = false
            })
        } else {
          isRequest.value = false
        }
      })
    }, 0)
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      const formData = new FormData()
      formData.append('image', refInputEl.value.files[0])
      axiosIns.post('upload/uploadAvatar', formData).then(res => {
        if (res.code == 0) {
          editInfo.value.logo = process.env.VUE_APP_COM_URL + res.data.url
        } else {
          proxy.$bvToast.toast('上传失败', {
            title: '提示',
            variant: 'danger',
            solid: true,
            autoHideDelay: 2000,
          })
        }
      })
    })
    return {
      avatarText,
      validationForm,

      isRequest,

      editInfo,
      required,
      url,

      brandEdit,
      inputImageRenderer,
      refInputEl,
      previewEl
    }
  },
}
</script>
